import { FaCarAlt, FaFileAlt } from "react-icons/fa";
const sideBarArr = [
  {
    pathname: [''],
    path: '',
    icon: <FaFileAlt style={{color:'#fff'}}/>,
    name: 'Mapping',
    sub: [
      {
        pathname: ['/mmv'],
        path: '/mmv',
        icon: <FaCarAlt  style={{color:"#fff"}}/>,
        name: 'MMV'
      },
      {
        pathname: ['/rto'],
        path: '/rto',
        icon: <FaCarAlt  style={{color:"#fff"}}/>,
        name: 'RTO'
      },
    ]
  },
  // { 
  //   pathname: [ '/users','/add-user'],
  //   path: '/users',
  //   icon: <i className='fa fa-user'></i>,
  //   name: 'Users',  
  // }
  

]

export default sideBarArr
