import {
  MMV_DATA,
  RESET_MMV_DATA,
  LOGGED_USER_DETAILS,
  // RTO_DATA
} from "../types";
import store from "../index";

export const saveUserDetails = (payload) => {
  return {
    type: LOGGED_USER_DETAILS,
    payload,
  };
};

export const mmvPayload = (payload) => {
  return {
    type: MMV_DATA,
    payload,
  };
};
// export const rtoPayload = (payload) => {
//   return {
//     type: RTO_DATA,
//     payload,
//   };
// };


  
export const resetapiRequestQQ = (payload) => {
  return {
    type: RESET_MMV_DATA,
    payload,
  };
};

export const dispatchMmvData = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(mmvPayload(obj));
};
// export const dispatchRtoData = (key, value) => {
//   let obj = {
//     key: key,
//     value: value,
//   };
//   store.dispatch(rtoPayload(obj));
// };


export const dispatchResetApiRequestQQ = (key, value) => {

  // debugger

   let obj = {
    mmvData: {
      vehicleType: '',
      partnerName: '',
      insurerName: '',
      insurerId:'',
      makeId: '',
      modelId: '',
      fuelType:'',
      varientId: '',
      insurerMmvId: '',
      action: '',
    },
    rtoData: {
      vehicleType: '',
      insurerName: '',
      state:"",
      city:"",
      rto:"",
      action: '',
    }
  };

  store.dispatch(resetapiRequestQQ(obj));
};






