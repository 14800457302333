import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { dispatchMmvData } from '../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper';
import ReactPaginate from 'react-paginate';
import ReactSelect from 'react-select'
import { toast } from 'react-toastify';

const Rto = () => {
    const mmvData = useSelector(state => state?.root?.mmvData)
    const dispatch = useDispatch()
    const apiUrl = {
        IF: 'https://api.insurefast.in/api/v1/',
        // IF: 'https://test.insurefast.in/api/v1/',
        EC: 'https://api.expertcover.in/api/v3/'
      }
    const [vehicleType, setVehicleType] = useState('')
    const [insurer, setInsurer] = useState('')
    const userDetails = useSelector(state => state?.root?.userDetails)
    const [insurerData, setInsurerData] = useState([])
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [currentItemsData, setCurrentItemsData] = useState(false)
    const [insurerRtoData, setInsurerRtoData] = useState([])
    const [partnerRtoData, setPartnerRtoData] = useState([])
    const [stateData, setStateData] = useState([])
    const [filter, setFilter] = useState(false)
    const [selectedState, setSelectedState] = useState(null)
    const [stateId, setStateId] = useState('')
    const [cityId, setCityId] = useState('')
    const [cityData,setCityData] = useState([])
    const [selectedCity,setSelectedCity] = useState(null)
    const [rtoData, setRtoData] = useState([])
    const [selectedRto, setSelectedRto] = useState(null)
    const [selectedValue, setSelectedValue] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [action, setAction] = useState(false)
    const [resetAction, setResetAction] = useState(false)
    const [variantIdInput, setVariantIdInput] = useState('')

    const [pageCount, setPageCount] = useState()
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [itemOffset, setItemOffset] = useState(0)
    const handlePageClick = e => {
        const newOffset = e.selected * itemsPerPage
        setItemOffset(newOffset)
      }
    
      const endOffset = itemOffset + itemsPerPage
      const currentItems =
        partnerRtoData && partnerRtoData.slice(itemOffset, endOffset)
      const handleChangeItemPerPage = e => {
        setItemsPerPage(e)
      }
    
    
    const handleSelectProduct = val => {
        setLoading(true);
        
        // Add query parameters correctly, removing any extra commas
        let data = new URLSearchParams({
          isMmvPanel: true,
          vehicleType: vehicleType, // Ensure this is passed without any extra commas or spaces
        }).toString();
      
        GetDataWithToken(val, `admin/getAllInsData?${data}`).then(res => {
          setLoading(false);
          if (res?.status === 'success') {
            setInsurerData(res?.data);
          } else {
            console.log('Insurer Data', res?.status);
          }
        });
      }
    const handleSelectVehicleType = val => {
        setInsurer('');
  dispatchMmvData('insurerId', '');
  dispatchMmvData('insurerName', '');
  dispatchMmvData('mappingName', '');
  setSelectedState(null);
  setSelectedCity(null);
  setSelectedRto(null);
  dispatchMmvData('stateId', '');
  dispatchMmvData('cityId', '');
  dispatchMmvData('RTO_Code', '');
  dispatchMmvData('action', '');
  setCurrentItemsData(false);

        dispatchMmvData('insurerId', ''); 
        dispatchMmvData('insurerName', '');
        
        // Set the vehicle type without extra characters
        const vehicleTypeClean = val?.trim();  // This ensures no trailing commas or spaces
        setVehicleType(vehicleTypeClean);
        dispatchMmvData('vehicleType', vehicleTypeClean);
      
        if (userDetails?.productOwner === 'EC') {
            dispatchMmvData('apiUrl', apiUrl?.EC);
        } else if (userDetails?.productOwner === 'IF') {
            dispatchMmvData('apiUrl', apiUrl?.IF);
          console.log("apiUrl?.IF------",apiUrl?.IF);
          
        }
        
      };
      useEffect(() => {
        if (vehicleType && mmvData?.apiUrl) {
          handleSelectProduct(mmvData?.apiUrl);
          console.log("rtoData?.apiUrl---",mmvData?.apiUrl);
          
        }
      }, [vehicleType, mmvData?.apiUrl]);
      const handleSelectInsurer = (val, insurerData) => {
        console.log("insurerData----",insurerData);
        console.log("val----", val);
        
        setInsurer(val)
        setCurrentItemsData(false)
        dispatchMmvData('insurerId', val)


        dispatchMmvData('insurerRtoId', '')
        const selectedInsurer = insurerData.find((x) => x.insurerId === parseInt(val))
        console.log("selectedInsurer--",selectedInsurer);
        
        const insurerName = selectedInsurer ? selectedInsurer.insurerName : ''
        const mappingName = selectedInsurer ? selectedInsurer.mappingName : ''
        dispatchMmvData('insurerName', insurerName)
        dispatchMmvData('mappingName', mappingName)
      }
      const validateForm = () => {
        const newErrors = {}
        if (!insurer) newErrors.insurer = 'Please select an insurer.'
        if (!vehicleType) newErrors.vehicleType = 'Please select a vehicle type.'
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
      }

      const getInsurerRto = () => {
        setLoading(true); // Start loading
      
        const insurerObj = {
          vehicleType: mmvData?.vehicleType,
          insurerId: mmvData?.insurerId || '',
        };
      
        PostDataWithToken(mmvData?.apiUrl, 'motor/getInsurerRtoData', insurerObj)
          .then((res) => {
            if (res?.status) {
              console.log("Response:", res);
      
              const data = res?.data || [];
              const arr = data.map((item) => ({
                label: item?.rtoFullName,
                value: item?.id,
              }));
      
              setInsurerRtoData(arr);
            } else {
              console.error("Error in response:", res?.message || "Unknown error");
            }
          })
          .catch((err) => {
            console.error("Error in API call:", err);
          })
          .finally(() => {
            setLoading(false); // Stop loading
          });
      };
      
      useEffect(() => {
        currentItems.map(item => {
          const value = insurerRtoData.find(data => item?.kotak === data?.value)
        })
      }, [currentItems])

      const getPartnerRto = (val1,val2,val3,val4) => {
        setLoading(true)
        let partnerObj = {
          stateId: val1 === mmvData?.makeId ? mmvData?.makeId : val1,
          vehicleType: mmvData?.vehicleType,
          insurerId: mmvData?.insurerId || '',
          rtoFullName : "",
          cityId: val2 === mmvData?.cityId ? mmvData?.cityId : val2,
          RTO_Code: val3 !== '' ? val3 : variantIdInput,
          action: val4 !== '' ? val4 : ''
        }
    
        PostDataWithToken(mmvData?.apiUrl, 'motor/getRtoData',partnerObj).then(
          res => {
            setPartnerRtoData(res?.data)
            setPageCount(Math.ceil(res?.data.length / itemsPerPage))
            setLoading(false)
          }
        )
        // setModel(true)
      }
      // const getStateData = (val) => {
      //   debugger
      //   setLoading(true);  // Start loading
      //   let stateObj = {
      //     status: "1",
      //     stateId:''
      //   };
      
      //   PostDataWithToken(mmvData?.apiUrl, 'motor/getState', stateObj)
      //     .then((res) => {
      //       if (res?.status) {
      //         let data = res?.data;
      //         let arr = data?.map(item => ({

      //           label: item?.state,
      //           value: item?.stateId
      //         }));
      
      //         setStateData(arr);
      //         console.log("arr---", arr);
      
      //       } else {
      //         console.error("Error fetching data:", res?.message || "Unknown error");
      //       }
      //     })
      //     .catch((err) => {
      //       console.error("Error in API call:", err);
      //     })
      //     .finally(() => {
      //       setLoading(false);  // Stop loading
      //     });
      // };
      const getStateData = val => {
        setLoading(true)
         let stateObj = {
          status: "1",
          stateId:''
        };
        PostDataWithToken(mmvData?.apiUrl, 'motor/getState', stateObj).then(
          (res) => { if (res?.status) {
            let data = res?.data
            let i = 0
            let arr = []
            while (i < data?.length) {
              let item = data[i]
              arr.push({
                label: item?.state,
                value: item?.stateId
              })
              i++
            }
            setStateData(arr)
            setLoading(false)
            
          }
           
          }
        )
      }

      const handleSubmit = () => {
        if (!validateForm()) return
        setCurrentItemsData(true)
        getInsurerRto()
        getPartnerRto()
        getStateData()
        setLoading(true)
  
      }
     const handleSelectInsurerRtoId = (selectedOption, itemId) =>{
      console.log("itemId",itemId);
      
      dispatchMmvData('insurerRtoId', selectedOption?.value)
      setSelectedValue(prevSelected => ({
        ...prevSelected,
        [itemId]: selectedOption
      }))
     }
     
      
      const handleFilter = () => {
        setFilter(!filter)
      }

      const resetData = () => {
    
        setSelectedRto(null)
        dispatchMmvData('RTO_Code', '')
        dispatchMmvData('cityId', '')
        setSelectedCity(null)
      }
      const handleSelectState = (val, selectedOption) => {
        console.log("vAL---",val);
        resetData()
        setStateId(val)
        setSelectedState(selectedOption)
        dispatchMmvData('stateId', val)
        resetAction === false
          ? getPartnerRto(val, '', '', '')
          : getPartnerRto(val, '', '', mmvData?.action)
        setResetAction(true)
      }
      
      // const getCityData = (val) => {
      //   debugger
      //   setCityData([]);
      //   setRtoData([]);
      //   dispatchMmvData('stateId', val); 
      
      //   setLoading(true);
      
      //   const cityObj = {
      //     status: 1,
      //     stateId: String(val), 
      //     cityId : ""
      //   };
      
      //   PostDataWithToken(mmvData?.apiUrl, '/motor/getCity', cityObj)
      //     .then((res) => {
      //       debugger
      //       const data = res?.data;
      //         let arr = data?.map(item => ({

      //           label: item?.city,
      //           value: item?.cityId
      //         }));
      
      //         setCityData(arr);
      //         console.log("arr---", arr);
      //     })
      //     .catch((error) => {
      //       console.error('Error fetching city data:', error);
      //     })
      //     .finally(() => {
      //       setLoading(false);
      //     });
      // };
      
      const getCityData = val => {
        setCityData([]);
        setRtoData([]);
        setLoading(true)
         const cityObj = {
          status: 1,
          stateId: String(val), 
          cityId : ""
        };
        PostDataWithToken(mmvData?.apiUrl, '/motor/getCity', cityObj).then(
          res => {
            let data = res
            let i = 0
            let arr = []
            while (i < data?.length) {
              let item = data[i]
              arr.push({
                label: item?.city,
                value: item?.cityId
              })
              i++
            }
            setCityData(arr)
            console.log("arrrr----",arr);
            
            setLoading(false)
          }
        )
      }

      const handleSelectCity = (val, selectedOption) => {
        setCityId(val)
        // setFuelType(null)
        setSelectedRto(null)
        setSelectedCity(selectedOption)
        dispatchMmvData('cityId', val)
        let actionVal = action ? mmvData?.action : ''
        getPartnerRto(mmvData?.stateId, val, '', actionVal)
      }

      const getRtoData = (val) => {
        setLoading(true); // Start loading
        dispatchMmvData('cityId', val);
        const makeObj = {
          stateId: stateId || mmvData?.stateId,
          cityId: val,
        };
      
        PostDataWithToken(mmvData?.apiUrl, 'motor/getRtoCode', makeObj)
          .then((res) => {
            if (res?.status) {
              const data = res?.data || [];
              const arr = data.map((item) => ({
                label: item?.RTO_Code,
                value: item?.RTO_Code,
              }));
      
              setRtoData(arr);
              console.log("RTO Data:", arr);
            } else {
              console.error("Error fetching RTO data:", res?.message || "Unknown error");
            }
          })
          .catch((err) => {
            console.error("Error in API call:", err);
          })
          .finally(() => {
            setLoading(false); // Stop loading
          });
      };
      const handleSelectRto = (val, selectedOption) => {
        
        setSelectedRto(selectedOption)
        dispatchMmvData('RTO_Code', val)
        getPartnerRto(mmvData?.stateId, mmvData?.cityId, val,)
      }


      const handleSelectMapData = val => {
        dispatchMmvData('action', val)
        setAction(true)
    
        setSelectedStatus(val) // Update state with the selected value
        getPartnerRto(mmvData.stateId, mmvData.cityId, mmvData.RTO_Code, val)
      }
      const resetPartnerRto = () => {
        setLoading(true)
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedRto(null)
        setSelectedStatus('')
        dispatchMmvData('stateId', '')
        dispatchMmvData('cityId', '')
        dispatchMmvData('RTO_Code', '')
        dispatchMmvData('action', '')
        dispatchMmvData('insurerRtoData', '')
        getPartnerRto('', '', '', '')
      }
      const handleMapping = (item, matchingRto) => {
        setLoading(true)
        let mapObj = {
          vehicleType: mmvData?.vehicleType,
          insurerRtoId: mmvData?.insurerRtoId
            ? mmvData?.insurerRtoId
            : matchingRto?.value,
          insurerId: mmvData?.insurerId,
          insFastRtoId: item,
          action: 'mapped'
        }
        PostDataWithToken(mmvData?.apiUrl, 'motor/mapRtoData', mapObj).then(res => {
          setLoading(false)
          if (res?.status === 'success') {
            toast.success('MMV Code Mapped!!')
          } else {
            toast.error('Something went wrong')
          }
        })
      }
      //unmap data
      const handleUnMapping = item => {
        setLoading(true)
        let mapObj = {
          vehicleType: mmvData?.vehicleType,
          insurerRtoId: '',
          insurerId: mmvData?.insurerId,
          insFastRtoId: item,
          action: 'Unmapped'
        }
        PostDataWithToken(mmvData?.apiUrl, 'motor/mapRtoData', mapObj).then(res => {
          setLoading(false)
          if (res?.status === 'success') {
            toast.success('MMV Code UnMapped!!')
          } else {
            toast.error('Something went wrong')
          }
        })
      }
      const handleVariantIdChange = e => {
        setVariantIdInput(e.target.value)
      }
      const [searchType, setSearchType] = useState('RTO_Code')
  const handleSearch = () => {
    if (searchType === 'RTO_Code') {
      getPartnerRto('', '', variantIdInput, '')
      setSelectedState(null)
      setSelectedCity(null)
      setSelectedRto(null)
    } else if (searchType === 'stateId') {
      getPartnerRto(stateId || mmvData?.stateId)
    } else if (searchType === 'cityId') {
      getPartnerRto(cityId || mmvData?.cityId)
    }
  }
  return (
    <Layout>
    <div className='container-fluid py-4 dash_main'>
        <div className='row'>
            <div className='card mb-4'>
                <div className='card-body pt-0 pb-2'>
                    <div className='row mt-2'>
                    <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                  <label>Vehicle Type</label>
                  <div className='mb-3'>
                    <select
                      className='form-control'
                      onChange={e => handleSelectVehicleType(e.target.value)}
                      value={vehicleType}
                      name='vehicleType'
                      id='vehicleType'
                    >
                      <option value=''>Select Vehicle</option>
                      <option value='2w'>Two Wheeler</option>
                      <option value='4w'>Private Car</option>
                      <option value='pcv'>Passenger Carrying Vehicle</option>
                      <option value='gcv'>Goods Carrying Vehicle</option>
                      <option value='misc'>Miscellaneous Vehicle</option>
                    </select>
                    {errors.vehicleType && (
                      <div className='text-danger'>{errors.vehicleType}</div>
                    )}
                  </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                  <label>Insurer</label>
                  <div className='mb-3'>
                    <select
                      className='form-control'
                      onChange={e =>
                        handleSelectInsurer(e.target.value, insurerData)
                      }
                      value={insurer}
                    >
                      <option value=''>Select Insurance</option>
                      {insurerData?.map((item, index) => (
                        <option key={index} value={item?.insurerId}>
                          {item?.mappingName}
                        </option>
                      ))}
                    </select>
                    {errors.insurer && (
                      <div className='text-danger'>{errors.insurer}</div>
                    )}
                  </div>
                </div>
                
                <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                  <label></label>
                  <div className='mb-3 d-flex justify-content-end gap-3'>
                    <button
                      type='button'
                      className='btn btn-sec'
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? 'Loading...' : 'Submit'}
                    </button>
                  </div>
                </div>

                    </div>
                </div>
            </div>

            
        </div>
        
        {currentItemsData ? (
            <>
              <div className='card mb-4 pt-2 pb-2'>
                <div onClick={handleFilter}>
                  <button
                    className={filter ? 'filter-btn active' : 'filter-btn'}
                  >
                    {' '}
                    Show Filter
                  </button>
                </div>
                {filter && (
                  <div className='card-body pt-0 pb-2'>
                    <div className='row'>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>State</label>
                        <ReactSelect
                          options={stateData}
                          name='Make'
                          placeholder='Select state'
                          value={selectedState}
                          onChange={e => {
                            handleSelectState(e?.value)
                            getCityData(e?.value)
                          }}
                        />
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>City</label>
                        <ReactSelect
                          options={cityData}
                          name='City'
                          placeholder='Select City'
                          value={selectedCity}
                          onChange={e => {
                            handleSelectCity(e?.value)
                            getRtoData(e?.value)
                          }}
                        />
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>Rto Code</label>
                        <ReactSelect
                          options={rtoData}
                          name='Model'
                          placeholder='Select Varient'
                          value={selectedRto}
                          onChange={e => handleSelectRto(e?.value)}
                        />
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>Status</label>
                        <div className='mb-3'>
                          <select
                            className='form-control'
                            value={selectedStatus} // Bind the selected value to state
                            onChange={e => handleSelectMapData(e.target.value)} // Call the handler on selection
                          >
                            <option key='' value=''>
                              All
                            </option>
                            <option key='mapped' value='mapped'>
                              Mapped
                            </option>
                            <option key='unmapped' value='unmapped'>
                              Unmapped
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-xl-12 col-lg-3 col-md-6 col-12 mt-4 d-flex justify-content-end align-items-center'>
                        <button
                          type='button'
                          className='btn btn-danger'
                          onClick={() => resetPartnerRto()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* </div> */}
              {loading ? (
                <>
                  <div className='loading'>
                    <div className='spinner'></div>
                    <p>Loading...</p>
                  </div>
                </>
              ) : (
                <>
                {currentItems?.length > 0 ? (
                    <div className='col-12'>
                      <div className='card mb-4'>
                      <div className='card-body pt-0 pb-2'>
                          <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12 d-flex align-items-center gao-1'>
                              <div>
                                <label>RTO Code</label>
                                <div className='mb-3'>
                                  <input
                                    type='search'
                                    placeholder='RTO CODE'
                                    className='form-control'
                                    value={variantIdInput}
                                    onChange={handleVariantIdChange}
                                  />
                                </div>
                              </div>
                              <div
                                className='mt-3 secrch-btn'
                                onClick={() => handleSearch()}
                              >
                                <button>search</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-body pt-0 pb-2'>
                          <div className='table-responsive p-0'>
                            <table className='table align-items-center mb-0'>
                              <thead>
                                <tr>
                                  <th className='text-uppercase text-xxs font-weight-bolder text-dark'>
                                    Product Mmv
                                  </th>
                                  <th className='text-uppercase text-xxs font-weight-bolder text-dark'>
                                    Insurer
                                  </th>
                                  <th
                                    className='text-uppercase  text-xxs font-weight-bolder fs-4'
                                    style={{ color: '#000' }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems.map((item, index) => {
                                  const insurerRtoId =
                                    item[mmvData?.mappingName]
                                  const insurerName = mmvData?.mappingName
                                  const matchingRto = insurerRtoData.find(
                                    data => data.value === insurerRtoId
                                  )
                                  console.log(insurerName, 'insurerName')
                                  return (
                                    <tr key={index}>
                                      <td className='select_partner'>
                                        {item?.rtoFullName}
                                      </td>
                                      <td style={{ width: '45%' }}>
                                        <div className='mmv_select'>
                                          <ReactSelect
                                            options={insurerRtoData}
                                            name='RTO'
                                            value={
                                              selectedValue[item.id] ||
                                              matchingRto ||
                                              null
                                            }
                                            placeholder='Select Rto'
                                            onChange={selectedOption =>
                                              handleSelectInsurerRtoId(
                                                selectedOption,
                                                item.id
                                              )
                                            }
                                            className='select_ins'
                                            menuPortalTarget={document.body}
                                            // components={{ MenuList }}
                                            styles={{
                                              menuPortal: base => ({
                                                ...base,
                                                zIndex: 9999
                                              })
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex gap-2'>
                                          <button
                                            type='button'
                                            className='btn btn-sec'
                                            onClick={() =>
                                              handleMapping(
                                                item?.id,
                                                matchingRto
                                              )
                                            }
                                          >
                                           
                                            map
                                          </button>
                                          <button
                                            type='button'
                                            className='btn suc-btn'
                                            onClick={() =>
                                              handleUnMapping(item?.id)
                                            }
                                          >
                                            
                                            Unmap
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='card mb-4 p-3'>
                        <div className='d-flex align-items-center justify-content-between flex-wrap'>
                          <div>
                            <p>
                              Showing 1 - {itemsPerPage} items of{' '}
                              {partnerRtoData?.length}
                            </p>
                          </div>
                          <div className='main-page-item'>
                            <div className='show-page'>
                              <select
                                className='form-control'
                                onClick={e =>
                                  handleChangeItemPerPage(e?.target?.value)
                                }
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                            <div>
                              <ReactPaginate
                                breakLabel='...'
                                nextLabel='>'
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel='<'
                                renderOnZeroPageCount={null}
                                pageClassName='page-item'
                                pageLinkClassName='page-link'
                                previousClassName='page-item'
                                previousLinkClassName='page-link'
                                nextClassName='page-item'
                                nextLinkClassName='page-link'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                containerClassName='pagination'
                                activeClassName='active'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p style={{ textAlign: 'center', fontSize: '1rem' }}>
                      {' '}
                      No Record Found
                    </p>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <p style={{ textAlign: 'center', fontSize: '1rem' }}>
                {' '}
                No Record Found
              </p>
            </>
          )}
    
    </div>
    </Layout>
  )
}

export default Rto